<template>
  <div class="container-page">
    <p class="title-inner">Финансовый сектор</p>
    <div class="mb-16">
      <ButtonStock
        class="mr-2"
        :disabled="importInProgress"
        :title="!importInProgress ? 'Обновить' : 'Загрузка...'"
        @click="refresh()"
      />
      <ButtonStock
        :disabled="importInProgress"
        :title="!importInProgress ? 'Загрузить из Excel' : 'Загрузка...'"
        @click="importExcel()"
      />
    </div>
    <DefaultFilter class="mb-16" :filterValue="filter" @loadFilter="onLoadFilter" />
    <div>
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Страна</th>
              <th>Доходность гос. ценных бумаг, %</th>
              <th>Капитализация рынка акций, трлн $ США</th>
              <th>m0</th>
              <th>m1</th>
              <th>m2</th>
              <th>m3</th>
            </tr>
            <tr>
              <th class="filter">
                <FilterInput
                  type="select2"
                  :select2-options="countries"
                  :select2-settings="select2Settings"
                  v-model="filter.countryId"
                  placeholder="(Все)"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <div class="range">
                  <FilterInput
                    type="number"
                    v-model="filter.yieldFrom"
                    class="mr-2"
                    placeholder="от"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                  <FilterInput
                    type="number"
                    v-model="filter.yieldTo"
                    placeholder="до"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                </div>
              </th>
              <th class="filter">
                <div class="range">
                  <FilterInput
                    type="number"
                    v-model="filter.totalMarketCapFrom"
                    class="mr-2"
                    placeholder="от"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                  <FilterInput
                    type="number"
                    v-model="filter.totalMarketCapTo"
                    placeholder="до"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                </div>
              </th>
              <th class="filter">
                <div class="range">
                  <FilterInput
                    type="number"
                    v-model="filter.m0From"
                    class="mr-2"
                    placeholder="от"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                  <FilterInput
                    type="number"
                    v-model="filter.m0To"
                    placeholder="до"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                </div>
              </th>
              <th class="filter">
                <div class="range">
                  <FilterInput
                    type="number"
                    v-model="filter.m1From"
                    class="mr-2"
                    placeholder="от"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                  <FilterInput
                    type="number"
                    v-model="filter.m1To"
                    placeholder="до"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                </div>
              </th>
              <th class="filter">
                <div class="range">
                  <FilterInput
                    type="number"
                    v-model="filter.m2From"
                    class="mr-2"
                    placeholder="от"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                  <FilterInput
                    type="number"
                    v-model="filter.m2To"
                    placeholder="до"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                </div>
              </th>
              <th class="filter">
                <div class="range">
                  <FilterInput
                    type="number"
                    v-model="filter.m3From"
                    class="mr-2"
                    placeholder="от"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                  <FilterInput
                    type="number"
                    v-model="filter.m3To"
                    placeholder="до"
                    v-on:valueChangedDebounced="changeFilter()"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id">
              <td>{{ item.country.nameFull || item.country.name }}</td>
              <td>{{ item.yield }}</td>
              <td>{{ (item?.totalMarketCap / 1000000000000)?.toFixed(6) }}</td>
              <td>{{ item.m0 }} {{ item.numberingM0 ? finSectorNumbering[item.numberingM0] : '' }}</td>
              <td>{{ item.m1 }} {{ item.numberingM1 ? finSectorNumbering[item.numberingM1] : '' }}</td>
              <td>{{ item.m2 }} {{ item.numberingM2 ? finSectorNumbering[item.numberingM2] : '' }}</td>
              <td>{{ item.m3 }} {{ item.numberingM3 ? finSectorNumbering[item.numberingM3] : '' }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="7">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="7">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
  </div>
  <section>
    <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal">
      <div class="mb-2">
        <ComponentInput
          type="select2"
          v-model="indicatorValue"
          label="Показатель"
          :select2Settings="select2SettingsNoClear"
          :select2Options="indicators"
        />
      </div>
      <ImportModal @doimport="doImport" @close="closeModal" />
    </ModalComponent>
  </section>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import DefaultFilter from '@/common/components/DefaultFilter';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import Utils from '@/common/utils';

  import ImportModal from '@/components/modals/ImportModal';

  import FilterInput from '../../../common/components/FilterInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import Api from '../api/index';
  export default {
    name: 'FinancialSector',
    components: { FilterInput, ButtonStock, DefaultFilter, ModalComponent, ImportModal, ComponentInput },
    mixins: [filtermanager],
    data() {
      return {
        route: 'financialsector',
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          countryId: null,
          yieldFrom: null,
          yieldTo: null,
          totalMarketCapFrom: null,
          totalMarketCapTo: null,
          m0From: null,
          m0To: null,
          m1From: null,
          m1To: null,
          m2From: null,
          m2To: null,
          m3From: null,
          m3To: null,
        },
        countries: [],
        select2Settings: Constants.select2Settings,
        importInProgress: false,
        importModal: false,
        isLoading: false,
        indicatorValue: 1,
        indicators: [
          { id: 1, text: 'M0' },
          { id: 2, text: 'M1' },
          { id: 3, text: 'M2' },
          { id: 4, text: 'M3' },
        ],
        select2SettingsNoClear: Constants.select2SettingsNoClear,
        finSectorNumbering: Constants.finSectorNumbering,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadDictionaries();
        this.loadPage();
      });
    },
    methods: {
      loadPage(append) {
        this.isLoading = !append;
        let filter = Object.assign({}, this.filter);
        if (filter.totalMarketCapFrom) filter.totalMarketCapFrom *= 1000000000000;
        if (filter.totalMarketCapTo) filter.totalMarketCapTo *= 1000000000000;
        const request = Object.assign(filter, this.request);
        Api.search(this.route, request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      loadDictionaries() {
        Utils.searchNsi('countrylistwithdata', {})
          .then((response) => {
            this.countries = response.data.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      refresh() {
        this.importInProgress = true;
        Api.refresh(this.route)
          .then(() => {
            Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
            this.loadPage();
            this.importInProgress = false;
          })
          .catch((error) => {
            console.log(error?.response?.data);
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
      importExcel() {
        this.importModal = true;
      },
      closeModal() {
        this.importModal = false;
      },
      doImport(file) {
        this.closeModal();
        this.importInProgress = true;
        Api.uploadFinancialSector(this.indicatorValue, file)
          .then(() => {
            Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
            this.loadPage();
            this.importInProgress = false;
            this.indicatorValue = 1;
          })
          .catch((error) => {
            console.log(error?.response?.data);
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
            this.indicatorValue = 1;
          });
      },
    },
  };
</script>

<style lang="scss">
  .filter {
    .range {
      display: flex;
      .input-group-wrapper {
        input {
          padding: 4px 8px;
          min-width: 80px;
          max-width: 80px;
        }
      }
    }
  }
</style>
